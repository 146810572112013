import { Injectable, EventEmitter } from "@angular/core";
import { UserManager, UserManagerSettings, User } from "oidc-client-ts";
import { ConfigService } from "../config/config.service";
import { BehaviorSubject, from, Observable, of, ReplaySubject } from "rxjs";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { AppState } from "src/app/@store/reducers";
import { JwtHelperService } from "@auth0/angular-jwt";
import { STSActions } from "src/app/@store/actions/sts.actions";
import { auditTime, catchError, filter, map, take, tap, throttleTime } from "rxjs/operators";
import { StsSelectors, checkTenant } from "src/app/@store/selectors/sts.selectors";
import { EStorageKeys } from "src/app/models/storage.model";
import {
  isPrivateRoute,
  showCookieBot,
} from "src/app/utils/common.utils";
import { LoggerService } from "../logger.service";
import { whiteLabelNames } from "src/app/appsettings";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UserInfo, UserRoleService } from "src/app/plasma-ui-common/services/user-role.service";
import { UserClaimsService } from "../accounts/user-claims.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private jwt = new JwtHelperService();
  public _manager: UserManager;
  public _user: User = null;
  userLoadededEvent: EventEmitter<User> = new EventEmitter<User>();

  public isLoggedIn: boolean = false;
  public profileInfo: Record<string, any> = {};
  public userInfo = new ReplaySubject<UserInfo>(1);

  public userInfo$: Observable<UserInfo> = this.userInfo.asObservable();
  public signinRedirectTrigger$ = new BehaviorSubject<any>(null);
  public signinRedirectCallbackTrigger$ = new BehaviorSubject<any>(null);

  constructor(
    private config: ConfigService,
    private router: Router,
    private store: Store<AppState>,
    private logger: LoggerService,
    private readonly userClaim: UserClaimsService,
    private readonly usertype: UserRoleService,

  ) {
    this._manager = new UserManager(this.getClientSettings());
    this.initializeUser();
    this.registerUserEvents();

    // subscribe to signin events
    this.signinRedirectTrigger$
      .pipe(takeUntilDestroyed(), filter(Boolean), auditTime(500))
      .subscribe((event) => this.addSignInEvents("signin"));
    this.signinRedirectCallbackTrigger$
      .pipe(takeUntilDestroyed(), filter(Boolean), auditTime(500))
      .subscribe((event) => this.addSignInEvents("signinCallback"));

    this.userClaim.claims$
      .pipe(takeUntilDestroyed(), throttleTime(100), filter(claims => Boolean(claims)))
      .subscribe(claims => this.saveUserInformation(claims));
  }

  private getClientSettings(): UserManagerSettings {
    const { OIDC } = this.config.envJson;
    const config = this.config._envConfig;

    const DEFAULT_RESPONSE_TYPE = "code";
    const DEFAULT_CLIENT_ID = "plasmaprofile-code-pkce";
    const DEFAULT_SCOPE = "openid profile email address user_type offline_access synlab_id IdentityServerApi";

    return {
      authority: config.BaseAuthUrl,
      client_id: OIDC?.ClientId || DEFAULT_CLIENT_ID,
      redirect_uri: `${config.BaseProfileUrl}/signin-callback`,
      post_logout_redirect_uri: config.BaseProfileUrl,
      response_type: OIDC?.ResponseType || DEFAULT_RESPONSE_TYPE,
      scope: OIDC?.Scope || DEFAULT_SCOPE,
      filterProtocolClaims: true,
      loadUserInfo: false,
      silent_redirect_uri: `${config.BaseProfileUrl}/renewtoken`,
      automaticSilentRenew: true,
      revokeTokensOnSignout: true,
      monitorSession: true,
      extraQueryParams: this.getExtraQueryParams(),
    };
  }

  private getExtraQueryParams() {
    const config = this.config._envConfig;
    if (checkTenant(whiteLabelNames.SPAIN_TELECOUNSELING, config)) {
      const returnTo = new URLSearchParams(window.location.search).get("uid");
      return returnTo ? { return_to: returnTo } : {};
    }
    return {};
  }

  private async initializeUser() {
    try {
      const user = await this._manager.getUser();
      if (user) {
        this.setUserLoggedIn(user);
      }
    } catch {
      this.isLoggedIn = false;
    }
  }

  private registerUserEvents() {
    this._manager.events.addUserLoaded((user) => this.setUserLoggedIn(user));
    this._manager.events.addUserUnloaded(() => {
      this.isLoggedIn = false;
    });
    //this._manager.events.addUserSessionChanged(() => this.signinRedirectTrigger$.next(true));
    this._manager.events.addUserSignedOut(() => {
      if (isPrivateRoute(this.router.url, this.router.config)) {
        localStorage.clear();
        sessionStorage.clear();
        this.signinRedirectTrigger$.next(true);
      }
    });
  }

  private setUserLoggedIn(user: User) {
    this._user = user;
    this.isLoggedIn = true;
    this.userLoadededEvent.emit(user);
    this.checkSavedUserInfo(user.access_token);
  }

  private checkSavedUserInfo(token: string) {
    this.store
      .pipe(
        select(StsSelectors.getSynlabId),
        filter((synlabId) => !synlabId),
        take(1),
        tap(() => this.saveToState(token))
      )
      .subscribe();
  }

  public saveToState(token: string) {
    showCookieBot(true, 500);
    const decoded = this.jwt.decodeToken(token);
    const { idp, langCode, sub } = decoded;
    this.store.dispatch(STSActions.loadStssSuccess({ data: { token, idp } as any }));
    this.userClaim.getUserClaims(sub);

    if (langCode) {
      this.userClaim.setAppLanguageFromJwtToken(langCode);
    }
  }

  async startSignoutMainWindow() {
    let redirectParams: Record<string, any> = {};
    try {
      const user = await this._manager.getUser();
      if (user) {
        redirectParams = { id_token_hint: user.id_token };
      }

      await this._manager.signoutRedirect(redirectParams);
        localStorage.clear();
        sessionStorage.clear();
        this.logger.log("Signout success", user);
    } catch (error) {
      this.logger.log("Signout error", error);
    }
  }

  public checkLogin(): Observable<boolean> {
    return from(this._manager.getUser()).pipe(map<User, boolean>((user) => true));
  }

  public isLoggedInObs(): Observable<boolean> {
    return from(this._manager.getUser()).pipe(map(Boolean));
  }

  private addSignInEvents(event: "signin" | "signinCallback") {
    if (event === "signin") {
      this.handleSigninRedirect();
    }
    if (event === "signinCallback") {
      this.handleSigninRedirectCallback();
    }
  }

  private handleSigninRedirect() {
    from(this._manager.signinRedirect().catch((e) => e))
      .pipe(
        take(1),
        tap(() => this.logger.log("signinRedirect success")),
        catchError((error) => {
          this.logger.log(error);
          throw new Error("signinRedirect error");
        })
      )
      .subscribe();
  }

  private handleSigninRedirectCallback() {
    from(this._manager.signinRedirectCallback().catch((e) => e))
      .pipe(
        take(1),
        tap((user) => {
          this.logger.log("signinRedirectCallback success", user);
          document.querySelector("body")?.classList.remove("signin-redirecting");

          const initialRoute = this.getInitialRoute();
          this.logger.log("Redirecting to initial route: ", initialRoute);

          this.router.navigateByUrl(initialRoute).then((done) => {
            this.logger.log("Redirected to initial route:", { initialRoute });
          });

          localStorage.removeItem(EStorageKeys.REDIRECT_URL);
        }),
        catchError((error) => {
          this.logger.log(error);
          throw new Error("signinRedirect error");
        })
      )
      .subscribe();
  }

  private getInitialRoute(): string {
    const redirectURL = localStorage.getItem(EStorageKeys.REDIRECT_URL);
    return (redirectURL?.includes("signin-callback") ? "/" : redirectURL) || "/";
  }

  private saveUserInformation(claims: Record<string, any>) {
    const { idp, langCode } = this.jwt.decodeToken(this._user.access_token);

    const additionalUserClaims = {
      idp,
      role: claims.user_type,
      token: this._user.access_token,
      name: `${claims.given_name} ${claims.family_name}`,
      synlabId: claims.synlab_id,
      country: claims.country,
      email: claims.email,
    };

    this.userInfo.next({ ...claims, idp, langCode } as any);
    this.profileInfo = { ...claims, ...additionalUserClaims };
    this.usertype.setUser(this.profileInfo);

    this.store.dispatch(STSActions.loadStssSuccess({ data: additionalUserClaims }));
    this.store.dispatch(STSActions.addUserInfo({ data: { ...claims, idp, langCode } as any }));
  }
}
